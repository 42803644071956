import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/messaging";
import { useSelector } from "react-redux";
import Loader from "../../components/loader/Loader";

const FirebaseData = () => {

  const setting = useSelector((state) => state.setting);

  if (setting.setting === null) {
    return <Loader screen='full' />;
  }
  console.log("setting ", setting);

  // const apiKey = setting.setting && setting.setting.firebase.apiKey;
  // const authDomain = setting.setting && setting.setting.firebase.authDomain;
  // const projectId = setting.setting && setting.setting.firebase.projectId;
  // const storageBucket = setting.setting && setting.setting.firebase.storageBucket;
  // const messagingSenderId = setting.setting && setting.setting.firebase.messagingSenderId;
  // const appId = setting.setting && setting.setting.firebase.appId;
  // const measurementId = setting.setting && setting.setting.firebase.measurementId;

  // if (apiKey === "") apiKey = "AIzaSyCu0k7-eZsZrDZ1cC5h1lEcDHu1z10gIfA";
  // if (authDomain === "") authDomain = "beldara-260cf.firebaseapp.com";
  // if (projectId === "") projectId = "beldara-260cf";
  // if (storageBucket === "") storageBucket = "beldara-260cf.appspot.com";
  // if (messagingSenderId === "") messagingSenderId = "644933295400";
  // if (appId === "") appId = "1:644933295400:web:67d84891ce3be83592841d";
  // if (measurementId === "") measurementId = "G-L6KN21MN7Z";

  const apiKey = "AIzaSyCu0k7-eZsZrDZ1cC5h1lEcDHu1z10gIfA";
  const authDomain = "beldara-260cf.firebaseapp.com";
  const projectId = "beldara-260cf";
  const storageBucket = "beldara-260cf.appspot.com";
  const messagingSenderId = "644933295400";
  const appId = "1:644933295400:web:67d84891ce3be83592841d";
  const measurementId = "G-L6KN21MN7Z";

  const firebaseConfig = {
    apiKey: apiKey,
    authDomain: authDomain,
    projectId: projectId,
    storageBucket: storageBucket,
    messagingSenderId: messagingSenderId,
    appId: appId,
    measurementId: measurementId,
  };

  console.log("firebaseConfig ", firebaseConfig);

  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);

  } else {
    firebase.app();
  }

  const auth = firebase.auth();

  const messaging = firebase.messaging();
  try {

    messaging.onMessage(function (payload) {
      // console.log("Message ->", payload);
      let data = payload?.data;
      // console.log(data);
      new Notification(data?.title, { body: data?.message, icon: data?.image || setting?.setting?.web_settings?.web_logo });
    });
  } catch (err) {
    console.log(err?.message);
  }
  return { auth, firebase, messaging };
};

export default FirebaseData;