import React from 'react'
import { useSelector } from 'react-redux'
import coverImg from '../../utils/cover-img.jpg'
import downloadapp from '../../utils/download-beldara-app.png'
import downloadandroid from '../../utils/download-beldara-android-app-playstore.png'
import downloadios from '../../utils/download-beldara-ios-app-appstore.png'
import { useParams } from 'react-router-dom';

import './Refer.css'
import Loader from '../loader/Loader'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { BrowserView, MobileView, isBrowser, isMobile, isAndroid, isIOS } from 'react-device-detect';


const Refer = () => {

    const { code } = useParams();



    if (isMobile == true && isAndroid == true) {
        window.location.href = "https://play.google.com/store/apps/details?id=grocery.beldara.fresh&referrer=utm_source=beldara&utm_target=" + { code } + "&utm_campaign=referandearn";

    }
    else if (isMobile == true && isIOS == true) {
        window.location.href = "https://apps.apple.com/app/beldara-10-minutes-delivery/id6670339576";
    }

    const setting = useSelector(state => (state.setting))
    const { t } = useTranslation()
    const placeHolderImage = (e) => {

        e.target.src = setting.setting?.web_logo
    }
    return (
        <section id='contact-us' className='contact-us'>
            {setting.setting === null ? <Loader screen='full' />
                : (
                    <>
                        <div className='cover'>
                            <img onError={placeHolderImage} src={coverImg} className='img-fluid' alt="cover"></img>
                            <div className='title'>
                                <h3>{t("contact_us")}</h3>
                                <span> <Link to="/" className='text-light text-decoration-none'>{t("home")} /</Link> </span><span className='active'>Download</span>
                            </div>
                        </div>
                        <div className='container'>

                            <div className='col-md-12 p-0 col-12 my-3 my-md-5'>
                                <img className='offerImages   cursorPointer' src={downloadapp} alt="Download Beldara App" />

                            </div>
                            <div className='row justify-content-center mx-3'>
                                {/* <div className="col-md-3 col-lg-2 col-6 col-sm-3 my-3 content"> */}

                                <div className="col-md-6 col-lg-6 col-12 col-sm-6 my-3 content">
                                    {setting.setting.web_settings.is_android_app === "1" ?
                                        <a href={setting.setting.web_settings.android_app_url + "&referrer=" + code} className='download-button'>
                                            <img className='offerImages   cursorPointer' src={setting.setting.web_settings.play_store_logo} alt='google-play'></img>
                                        </a>

                                        : <></>}
                                    {/* <img className='offerImages   cursorPointer' src={downloadandroid} alt="Download Beldara android App" /> */}

                                </div>
                                <div className="col-md-6 col-lg-6 col-12 col-sm-6 my-3 content">
                                    {setting.setting.web_settings.is_ios_app === "1" ?
                                        <a href={setting.setting.web_settings.ios_app_url} className='download-button'>
                                            <img className='offerImages   cursorPointer' src={setting.setting.web_settings.ios_store_logo} alt='google-play'></img>
                                        </a>
                                        : <></>}
                                    {/* <img className='offerImages   cursorPointer' src={downloadios} alt="Download Beldara ios apple App" /> */}

                                </div>
                            </div>
                        </div>
                    </>
                )
            }


        </section >
    )
}

export default Refer
